import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// config
//
import { getActive } from 'src/components/nav-section';
import navConfig from './NavConfig';

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
}));

export function IconTabBar() {
  const { pathname } = useLocation();

  const tabItems: {
    key: string;
    title: string;
    icon: JSX.Element;
    path: string;
    active: boolean;
  }[] = [];
  let activeIndex = 0;
  for (const subheader of navConfig) {
    for (const item of subheader.items) {
      if (item.tabbed) {
        const active = getActive(item.path, pathname);
        tabItems.push({
          key: item.path,
          title: item.title,
          icon: item.icon as JSX.Element,
          path: item.path,
          active,
        });
        if (active) {
          activeIndex = tabItems.length - 1;
        }
      }
    }
  }

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    navigate(tabItems[newValue].path);
  };

  return (
    <RootStyle>
      <Divider />
      <Tabs
        value={activeIndex}
        onChange={handleChange}
        aria-label="icon tabs example"
        variant="fullWidth"
      >
        {tabItems.map((_) => (
          <Tab key={_.key} icon={_.icon} aria-label={_.title} sx={{ p: 1 }} />
        ))}
      </Tabs>
    </RootStyle>
  );
}
