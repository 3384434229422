import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Slide,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useResponsive from 'src/hooks/useResponsive';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import TabbedNavbarAccount from '../navbar/TabbedNavbarAccount';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  // ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  // height: HEADER.MOBILE_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

export default function TabbedAccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  // const isDesktop = useResponsive('up', 'lg');
  const isDesktopOrTablet = useResponsive('up', 'md');

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      {isDesktopOrTablet && (
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {user?.fullName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
              >
                {option.label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </MenuPopover>
      )}

      {!isDesktopOrTablet && (
        <Dialog
          fullScreen
          open={Boolean(open)}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <StyledAppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Sound
              </Typography>
            </Toolbar>
          </StyledAppBar>
          <TabbedNavbarAccount isCollapse={false} />
          <Typography variant="subtitle2" sx={{ m: 2, mt: 4 }}>
            App Settings
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Iconify icon="bx:bell" />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Iconify icon="eva:settings-outline" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Iconify icon="bx:help-circle" />
                </ListItemIcon>
                <ListItemText primary="Help &amp; Support" />
              </ListItemButton>
            </ListItem>
          </List>
          <Stack sx={{ m: 2, mt: 4 }}>
            <Button variant="outlined" color="secondary" onClick={handleLogout}>
              Sign out
            </Button>
          </Stack>
          <Typography variant="caption" align="center" sx={{ mt: 6 }}>
            Lovia vX.Y.Z
          </Typography>
        </Dialog>
      )}
    </>
  );
}
