import FusionAuthClient from '@fusionauth/typescript-client';
import jwtDecode from 'jwt-decode';
import { config } from 'src/config';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string, forwardSeconds: number) => {
  if (!accessToken) {
    return false;
  }
  try {
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp >= currentTime + forwardSeconds;
  } catch (err) {
    console.error('Error decoding JWT token:', accessToken, err);
    return false;
  }
};

let expiredTimer: NodeJS.Timeout;

/**
 * Subscribes to TokenExpired event, which:
 * 1. tries to refresh the JWT token from FusionAuth
 * 2. if that failed, will redirect user to the sign in page
 *
 * @param exp Expiration in milliseconds
 * @param refreshToken FusionAuth refresh token
 */
const subscribeTokenExpiredEvent = (exp: number, refreshToken: string) => {
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(async () => {
    try {
      // console.debug('Refreshing Realm token for', app.currentUser.id, '...');
      // await app.currentUser?.refreshAccessToken();
      const client = new FusionAuthClient(
        config.fusionauth.clientKey,
        config.fusionauth.url,
        config.fusionauth.tenantId
      );
      console.debug(`Refreshing FusionAuth access token for app ${config.fusionauth.appId} ...`);
      // https://fusionauth.io/docs/v1/tech/apis/jwt#refresh-a-jwt
      const res = await client.exchangeRefreshTokenForJWT({
        refreshToken,
      });
      if (res.wasSuccessful()) {
        console.debug(`FusionAuth access token refreshed`);
        const accessToken = res.response.token!;
        const refreshToken = res.response.refreshToken!;
        // save the new tokens in storage for upcoming refetch
        setSession(accessToken, refreshToken);
        // return successfully, without redirect
        return true;
      } else {
        console.error('Error refreshing FusionAuth access token:', res.statusCode, res.exception);
      }
    } catch (err) {
      console.error('Network Error refreshing FusionAuth access token:', err);
    }

    // refresh JWT token didn't work, fallback to alerting user and redirect to sign in page
    alert('Token expired');
    setSession(null, null);
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

/**
 * This function does three things:
 * 1. Sets the access token and refresh token to local storage (which may already existed)
 * 2. Subscribes to TokenExpired event and handles automatic JWT refresh
 * 3. Sets the Axios default Authorization header to the current access token
 *
 * @param accessToken
 * @param refreshToken
 */
export const setSession = (accessToken: string | null, refreshToken: string | null) => {
  if (accessToken && refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    subscribeTokenExpiredEvent(exp, refreshToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
