import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import FusionAuthClient from '@fusionauth/typescript-client';
import { config } from 'src/config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken && isValidToken(accessToken, 15)) {
          setSession(accessToken, refreshToken);

          const client = new FusionAuthClient(
            config.fusionauth.clientKey,
            config.fusionauth.url,
            config.fusionauth.tenantId
          );
          console.debug('Checking FusionAuth user using JWT...');
          const response = await client.retrieveUserUsingJWT(accessToken);
          const { user } = response.response;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: user!,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error('Error checking FusionAuth user using JWT', err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const client = new FusionAuthClient(
      config.fusionauth.clientKey,
      config.fusionauth.url,
      config.fusionauth.tenantId
    );
    console.debug(`Signing in to FusionAuth app ${config.fusionauth.appId} using ${email} ...`);
    try {
      const res = await client.login({
        applicationId: config.fusionauth.appId,
        loginId: email,
        password,
      });
      console.debug(
        `Sign in status: ${res.statusCode} - ${res.response?.user?.id} ${res.response?.user?.fullName}`
      );
      if (res.wasSuccessful()) {
        const accessToken = res.response.token!;
        const refreshToken = res.response.refreshToken!;
        const user: AuthUser = res.response.user!;
        // const { accessToken, user } = response.data;
        console.debug('Sign in successful as:', user);

        setSession(accessToken, refreshToken);

        dispatch({
          type: Types.Login,
          payload: {
            user,
          },
        });
      } else {
        console.error(`Sign in failed: ${res.statusCode} - ${res.exception?.message}`);
        throw new Error(`Sign in failed: ${res.statusCode} - ${res.exception?.message}`);
      }
    } catch (err) {
      console.error('Sign in failed:', err);
      throw new Error(`Sign in failed: ${err.statusCode}`);
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
