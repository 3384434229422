import FusionAuthClient from '@fusionauth/typescript-client';
import { authExchange } from '@urql/exchange-auth';
import { config } from 'src/config';
import { cacheExchange, createClient, dedupExchange, fetchExchange, makeOperation } from 'urql';
import { isValidToken, setSession } from './jwt';

export const HASURA_GRAPHQL_URL =
  process.env.REACT_APP_HASURA_GRAPHQL_URL ?? 'http://localhost:8080/v1/graphql';

console.info(`Hasura GraphQL URL: ${HASURA_GRAPHQL_URL}`);

export const client = createClient({
  url: HASURA_GRAPHQL_URL,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<{ accessToken: string; refreshToken: string }>({
      addAuthToOperation: ({ authState, operation }) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState || !authState.accessToken) {
          return operation;
        }

        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              // 'X-Hasura-Admin-Secret': HASURA_GRAPHQL_ADMIN_SECRET,
              Authorization: `Bearer ${authState.accessToken}`,
              'X-Hasura-Role': 'user',
            },
          },
        });
      },
      willAuthError: ({ authState }) => {
        if (!authState) return true;
        // e.g. check for expiration, existence of auth etc
        return isValidToken(authState.accessToken, 15);
      },
      didAuthError: ({ error }) =>
        // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
        // Hasura uses extensions.code 'invalid-jwt'
        error.response?.status === 401 ||
        error.graphQLErrors.some(
          (e) => e.extensions?.code === 'FORBIDDEN' || e.extensions?.code === 'invalid-jwt'
        ),
      getAuth: async ({ authState, mutate }) => {
        // for initial launch, fetch the auth state from storage (local storage, async storage, etc.)
        if (!authState) {
          const accessToken = localStorage.getItem('accessToken');
          const refreshToken = localStorage.getItem('refreshToken');
          if (accessToken && refreshToken) {
            return {
              accessToken: accessToken,
              refreshToken: refreshToken,
            };
          }
          // const { token, refreshToken } = readAuthFromLocalStorage();
          // if (token && refreshToken) {
          //   return { token, refreshToken };
          // }
          return null;
        }

        /**
         * the following code gets executed when an auth error has occurred
         * we should refresh the token if possible and return a new auth state
         * If refresh fails, we should log out
         **/

        // if your refresh logic is in graphQL, you must use this mutate function to call it
        // if your refresh logic is a separate RESTful endpoint, use fetch or similar
        if (authState.refreshToken) {
          try {
            // console.debug('Refreshing Realm token for', app.currentUser.id, '...');
            // await app.currentUser?.refreshAccessToken();
            const client = new FusionAuthClient(
              config.fusionauth.clientKey,
              config.fusionauth.url,
              config.fusionauth.tenantId
            );
            console.debug(
              `Refreshing FusionAuth access token for app ${config.fusionauth.appId} ...`
            );
            // https://fusionauth.io/docs/v1/tech/apis/jwt#refresh-a-jwt
            const res = await client.exchangeRefreshTokenForJWT({
              refreshToken: authState.refreshToken,
            });
            if (res.wasSuccessful()) {
              console.debug(`FusionAuth access token refreshed`);
              const accessToken = res.response.token!;
              const refreshToken = res.response.refreshToken!;
              // save the new tokens in storage for upcoming refetch
              setSession(accessToken, refreshToken);

              // return the new tokens
              return {
                accessToken,
                refreshToken,
              };
            } else {
              console.error(
                'Error refreshing FusionAuth access token:',
                res.statusCode,
                res.exception
              );
            }
          } catch (err) {
            console.error('Network Error refreshing FusionAuth access token:', err);
          }
        }

        // otherwise, if refresh fails, log clear storage and log out
        // await processSignOut();
        setSession(null, null);

        return null;
      },
    }),
    fetchExchange,
  ],
});
